import { Module, ActionContext, Plugin } from 'vuex';
import cloneDeep from 'lodash-es/cloneDeep';
import numeral from 'numeral';
import { GROWER } from '@growmark/gcmp/types';
import { useRegistrationAPI, authz, versioned } from '@/composable/api/axois';
import { AxiosResponse } from 'axios';
import { ADDRESS } from '@/types/ADDRESS';
import { interruptable } from '@growmark/coroutine';
import { reactive } from 'vue';
import * as addressState from '@/store/address/state';
import * as errorState from '@/store/error/state';

export const NAMESPACE = 'grower';
export type State = typeof initial;
const initial = {
  redirect_url: <string | undefined>undefined,
  first_name: <string | undefined>undefined,
  last_name: <string | undefined>undefined,
  phone: <string | undefined>undefined,
  business_name: <string | undefined>undefined,
  business_phone: <string | undefined>undefined,
  address_1: <string | undefined>undefined,
  address_2: <string | undefined>undefined,
  city: <string | undefined>undefined,
  state: <string | undefined>undefined,
  zip: <string | undefined>undefined,
  country: <string | undefined>undefined,
  latitude: <number | undefined>undefined,
  longitude: <number | undefined>undefined,
  address_verified: <boolean | undefined>undefined,
  affiliations: <Array<GROWER.Affiliation>>[],
  grower_ids: <Array<GROWER.GrowerID>>[]
};

const local = reactive({
  finished_saving: false,
  grower_info_loading: false,
  grower_ids_loading: false,
  grower_affiliations_loading: false,
  grower_info_processing: false,
  grower_ids_processing: false,
  grower_affiliations_processing: false
});

export const plugin: Plugin<MinimalRootState> = async (store) => {
  try {
    store.dispatch(`${NAMESPACE}/get_grower_info`);
    store.dispatch(`${NAMESPACE}/get_grower_affiliations`);
    store.dispatch(`${NAMESPACE}/get_grower_ids`);
  } catch (err: any) {
    store.dispatch(`${errorState.NAMESPACE}/report_error`, { err, message: "Failed to load the users information." });
  }
};

export type Actions = typeof actions;
type MinimalRootState = { [NAMESPACE]: State };
type UserActionContext = ActionContext<State, MinimalRootState>;
export const actions = {
  save_redirect_url: async ({ commit }: UserActionContext, redirect_url: string) => {
    try {
      const url = new URL(redirect_url);
      commit('SAVE_REDIRECT_URL', url);
    } catch (err) {
      console.error(redirect_url, 'Not a valid URL', err);
    }
  },
  finished_saving: async (_: UserActionContext, is_done: boolean) => local.finished_saving = is_done,
  get_grower_info: async ({ commit, dispatch }: UserActionContext) => {
    local.grower_info_loading = true;
    const { data } = await useRegistrationAPI([authz, versioned]).get({ url: '/grower'}) as AxiosResponse<GROWER.General>;
    if (!data.address) { data.address = {} }
    local.grower_info_loading = false;
    commit('GET_GROWER_INFO', data);
    if (!data.address.latitude && !data.address.longitude) { await dispatch('check_for_gps_coords'); }
  },
  get_grower_affiliations: async ({ commit }: UserActionContext) => {
    local.grower_affiliations_loading = true;
    const { data } = await useRegistrationAPI([authz, versioned]).get({ url: '/grower/affiliations'}) as AxiosResponse<GROWER.Affiliation>;
    local.grower_affiliations_loading = false;
    commit('GET_GROWER_AFFILIATIONS', data);
  },
  get_grower_ids: async ({ commit }: UserActionContext) => {
    local.grower_ids_loading = true;
    const { data } = await useRegistrationAPI([authz, versioned]).get({ url: '/grower/ids'}) as AxiosResponse<GROWER.GrowerID>;
    local.grower_ids_loading = false;
    commit('GET_GROWER_IDS', data);
  },
  put_grower_info: async ({ dispatch, state }: UserActionContext) => {
    local.grower_info_processing = true;
    try {
      const general = <GROWER.General>{
        first_name: state.first_name,
        last_name: state.last_name,
        phone: numeral(state.phone?.replace(/\D/g,'')).value(),
        business_name: state.business_name,
        business_phone: numeral(state.business_phone?.replace(/\D/g,'')).value(),
        address: {
          address_1: state.address_1,
          address_2: state.address_2,
          city: state.city,
          country: state.country,
          latitude: state.latitude,
          longitude: state.longitude,
          state: state.state,
          zip: state.zip
        }
      };

      await useRegistrationAPI([authz, versioned]).put({ url: '/grower', data: { general }});
      dispatch('get_grower_info');
    } finally {
      local.grower_info_processing = false;
    }
  },
  put_grower_affiliations: async ({ dispatch, state }: UserActionContext) => {
    local.grower_affiliations_processing = true;
    await useRegistrationAPI([authz, versioned]).put({ url: '/grower/affiliations', data: { affiliations: state.affiliations }});
    local.grower_affiliations_processing = false;
    dispatch('get_grower_affiliations');
  },
  put_grower_ids: async ({ dispatch, state }: UserActionContext) => {
    local.grower_ids_processing = true;
    await useRegistrationAPI([authz, versioned]).put({ url: '/grower/ids', data: { grower_ids: state.grower_ids }});
    local.grower_ids_processing = false;
    dispatch('get_grower_ids');
  },
  reset_grower_info: async ({ commit }: UserActionContext) => commit('RESET_GROWER_INFO'),
  reset_grower_ids: async ({ commit }: UserActionContext) => commit('RESET_GROWER_IDS'),
  reset_affiliations: async ({ commit }: UserActionContext) => commit('RESET_AFFILIATIONS'),
  update_first_name: async ({ commit }: UserActionContext, first_name: string | undefined) => commit('UPDATE_FIRST_NAME', first_name),
  update_last_name: async ({ commit }: UserActionContext, last_name: string | undefined) => commit('UPDATE_LAST_NAME', last_name),
  update_phone: async ({ commit }: UserActionContext, phone: string | undefined) => commit('UPDATE_PHONE', phone),
  update_business_name: async ({ commit }: UserActionContext, business_name: string | undefined) => commit('UPDATE_BUSINESS_NAME', business_name),
  update_business_phone: async ({ commit }: UserActionContext, business_phone: string | undefined) => commit('UPDATE_BUSINESS_PHONE', business_phone),
  update_address_1: async ({ commit, dispatch, state }: UserActionContext, address_1: string | undefined) => {
    commit('UPDATE_ADDRESS_1', address_1);
    dispatch('check_for_gps_coords');
    const payload = { address_1, address_2: state.address_2, city: state.city, state: state.state, zip: state.zip }
    dispatch(`${addressState.NAMESPACE}/lookup_address`, payload, { root: true });
  },
  update_address_2: async ({ commit }: UserActionContext, address_2: string | undefined) => commit('UPDATE_ADDRESS_2', address_2),
  update_city: async ({ commit, dispatch }: UserActionContext, city: string | undefined) => {
    commit('UPDATE_CITY', city);
    dispatch('check_for_gps_coords');
  },
  update_address_state: async ({ commit, dispatch }: UserActionContext, address_state: string | undefined) => {
    commit('UPDATE_ADDRESS_STATE', address_state);
    dispatch('check_for_gps_coords');
  },
  update_zip: async ({ commit, dispatch }: UserActionContext, zip: string | undefined) => {
    commit('UPDATE_ZIP', zip);
    dispatch('check_for_gps_coords');
  },
  update_country: async ({ commit }: UserActionContext, country: string | undefined) => commit('UPDATE_COUNTRY', country),
  update_gps_coords: async ({ commit }: UserActionContext, { latitude, longitude }: { latitude: number | undefined, longitude: number | undefined }) => {
    commit('UPDATE_GPS_COORDINATES', { latitude, longitude });
  },
  check_for_gps_coords: interruptable(function*({ commit, state }: UserActionContext) {
    yield new Promise(resolve => setTimeout(resolve, 2000));
    if (state.address_1 && state.city && state.state && state.zip) {
      const address = state.address_1 + " " + state.address_2 + " " + state.city + " " + state.state + " " + state.zip;
      const { data }: { data: ADDRESS[] } = yield useRegistrationAPI([authz, versioned]).get({ url: '/address', query: { address }});
      console.log(`check_for_gps_coords: ${JSON.stringify(data)}`);
      commit('UPDATE_GPS_COORDINATES', { latitude: data[0].lat, longitude: data[0].lng });
    }
  }),
  add_grower_id: async ({ commit }: UserActionContext, grower_id: GROWER.GrowerID) => {
    commit('ADD_GROWER_ID', grower_id);
  },
  remove_grower_id: async ({ commit }: UserActionContext, grower_id: GROWER.GrowerID) => {
    commit('REMOVE_GROWER_ID', grower_id);
  },
  add_affiliation: async ({ commit }: UserActionContext, affiliation: GROWER.Affiliation) => {
    commit('ADD_AFFILIATION', affiliation);
  },
  remove_affiliation: async ({ commit }: UserActionContext, affiliation: GROWER.Affiliation) => {
    commit('REMOVE_AFFILIATION', affiliation);
  },
};

export type Getters = typeof getters;
export const getters = {
  redirect_url: (state: State) => state.redirect_url,
  finished_saving: () => local.finished_saving,
  is_grower_info_loading: () => local.grower_info_loading,
  is_grower_ids_loading: () => local.grower_ids_loading,
  is_grower_affiliations_loading: () => local.grower_affiliations_loading,
  is_processing: () => local.grower_affiliations_processing || local.grower_ids_processing || local.grower_info_processing,
  is_address_verified: (state: State) => state.address_verified,
  is_affiliation_verified: (state: State) => state.affiliations.filter(affiliation => !!affiliation.verified).length > 0,
  is_grower_id_verified: (state: State) => state.grower_ids.filter(grower_id => !!grower_id.verified && !!grower_id.affiliation.verified).length > 0,
  first_name: (state: State) => state.first_name,
  last_name: (state: State) => state.last_name,
  phone: (state: State) => state.phone,
  business_name: (state: State) => state.business_name,
  business_phone: (state: State) => state.business_phone,
  address_1: (state: State) => state.address_1,
  address_2: (state: State) => state.address_2,
  city: (state: State) => state.city,
  state: (state: State) => state.state,
  zip: (state: State) => state.zip,
  country: (state: State) => state.country,
  latitude: (state: State) => state.latitude,
  longitude: (state: State) => state.longitude,
  affiliations: (state: State) => state.affiliations.map(item => item),
  grower_ids: (state: State) => state.grower_ids.map(item => item)
};

export type Mutations = typeof mutations;
export const mutations = {
  SAVE_REDIRECT_URL: (state: State, redirect_url: string) => state.redirect_url = redirect_url,
  GET_GROWER_INFO: (state: State, general: GROWER.General) => {
    state.first_name = general.first_name;
    state.last_name = general.last_name;
    state.phone = general.phone?.toString();
    state.business_name = general.business_name?.toString();
    state.business_phone = general.business_phone?.toString();
    state.address_1 = general.address.address_1;
    state.address_2 = general.address.address_2;
    state.city = general.address.city;
    state.state = general.address.state;
    state.zip = general.address.zip;
    state.country = general.address.country;
    state.latitude = general.address.latitude;
    state.longitude = general.address.longitude;
    state.address_verified = general.address.verified;
  },
  GET_GROWER_AFFILIATIONS: (state: State, affiliations: GROWER.Affiliation[]) => {
    state.affiliations = affiliations;
  },
  GET_GROWER_IDS: (state: State, grower_ids: GROWER.GrowerID[]) => {
    state.grower_ids = grower_ids;
  },
  RESET_GROWER_INFO: (state: State) => {
    state.first_name = '';
    state.last_name = '';
    state.phone = '';
    state.business_name = '';
    state.business_phone = '';
    state.address_1 = '';
    state.address_2 = '';
    state.city = '';
    state.state = '';
    state.zip = '';
    state.country = '';
    state.latitude = 0;
    state.longitude = 0;
    state.address_verified = false;
  },
  RESET_GROWER_IDS: (state: State) => state.grower_ids = [],
  RESET_AFFILIATIONS: (state: State) => state.affiliations = [],
  UPDATE_FIRST_NAME: (state: State, first_name: string | undefined) => state.first_name = first_name,
  UPDATE_LAST_NAME: (state: State, last_name: string | undefined) => state.last_name = last_name,
  UPDATE_PHONE: (state: State, phone: string | undefined) => state.phone = phone,
  UPDATE_BUSINESS_NAME: (state: State, business_name: string | undefined) => state.business_name = business_name,
  UPDATE_BUSINESS_PHONE: (state: State, business_phone: string | undefined) => state.business_phone = business_phone,
  UPDATE_ADDRESS_1: (state: State, address_1: string | undefined) => state.address_1 = address_1,
  UPDATE_ADDRESS_2: (state: State, address_2: string | undefined) => state.address_2 = address_2,
  UPDATE_CITY: (state: State, city: string | undefined) => state.city = city,
  UPDATE_ADDRESS_STATE: (state: State, address_state: string | undefined) => state.state = address_state,
  UPDATE_ZIP: (state: State, zip: string | undefined) => state.zip = zip,
  UPDATE_COUNTRY: (state: State, country: string | undefined) => state.country = country,
  UPDATE_GPS_COORDINATES: (state: State, { latitude, longitude }: { latitude: number | undefined, longitude: number | undefined }) => {
    state.latitude = latitude;
    state.longitude = longitude;
  },
  ADD_GROWER_ID: (state: State, grower_id: GROWER.GrowerID) => {
    state.grower_ids.push(grower_id);
  },
  REMOVE_GROWER_ID: (state: State, grower_id: GROWER.GrowerID) => {
    state.grower_ids = state.grower_ids.filter(existing => existing !== grower_id)
  },
  ADD_AFFILIATION: (state: State, affiliation: GROWER.Affiliation) => {
    state.affiliations.push(affiliation);
  },
  REMOVE_AFFILIATION: (state: State, affiliation: GROWER.Affiliation) => {
    state.affiliations = state.affiliations.filter(existing => existing !== affiliation)
  },
};

export const module: Module<State, MinimalRootState> = {
  namespaced: true,
  state: cloneDeep(initial),
  getters,
  mutations,
  actions
};
