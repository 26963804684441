import store from '@/plugins/vuex';
import * as helpers from 'vuex-composition-helpers';
import { NAMESPACE, Getters, Mutations, State, Actions } from '@/store/grower/state';

const { useState, useGetters, useMutations, useActions } = helpers.createNamespacedHelpers<State, Getters, Actions, Mutations>(store, NAMESPACE);
type StateUseFunction = typeof useState;
type GettersUseFunction = typeof useGetters;
type MutationsUseFunction = typeof useMutations;
type ActionsUseFunction = typeof useActions;

export { StateUseFunction, GettersUseFunction, MutationsUseFunction, ActionsUseFunction };
export { useState, useGetters, useMutations, useActions };