<template>
  <v-dialog
    :value="!!show_dialog"
    max-width="700"
    persistent
  >
    <v-card>
      <v-card-title class="text-h5 grey lighten-2">
        Completed
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-row v-if="is_address_verified && is_affiliation_verified && is_grower_id_verified">
            <v-col cols="12"></v-col>
            <v-col cols="12" class="green lighten-5 rounded-lg">
              <v-icon
                large
                color="green darken-2"
              >
                mdi-check-circle-outline
              </v-icon>
              Successfully updated.
              <p v-if="redirect_message" class="mb-0">{{ redirect_message }}</p>
            </v-col>
          </v-row>

          <v-row v-else>
            <v-col cols="12"></v-col>
            <v-col cols="12" class="orange lighten-5 rounded-lg">
              <v-icon
                large
                color="orange darken-2"
              >
                mdi-alert
              </v-icon>
              Successfully updated.
              <p>The FS company needs to verify your account. Please allow a few business days for them to verify your account. When the account has been verified, you will receive an email to that effect.</p>
              <p v-if="redirect_message" class="mb-0">{{ redirect_message }}</p>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          text
          @click="dismiss"
        >
          {{ dismiss_button_text }}
        </v-btn>
        <v-btn
          v-if="!!redirect_url"
          color="primary"
          text
          @click="redirect"
        >
          {{ redirect_button_text }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
  import { computed, defineComponent } from 'vue';
  import { useRoute } from 'vue-router/composables'
  import * as growerStore from '@/store/grower';
	import * as errorStore from "@/store/error";

  export default defineComponent({
    name: 'finished-dialog',
    setup() {
      const route = useRoute();
      const { report_error } = errorStore.useActions(['report_error']);
      const { finished_saving } = growerStore.useActions(['finished_saving']);
      const {
        finished_saving: show_dialog, redirect_url,
        is_address_verified, is_affiliation_verified, is_grower_id_verified
      } = growerStore.useGetters(['redirect_url', 'finished_saving', 'is_address_verified', 'is_affiliation_verified', 'is_grower_id_verified']);

      const title = computed(() => route?.meta?.title);

      const redirect_url_hostname = computed(() => {
        if (!redirect_url.value) return undefined;
        try {
          const url = new URL(redirect_url.value);
          return url.hostname;
        } catch (error: any) {
          console.error(redirect_url, 'Not a valid URL', error);
          report_error({error, message: 'There was an issue saving your information.'});
        }
        return undefined;
      });

      const redirect_message = computed(() => {
        return redirect_url.value ?
          `You can choose to stay on this page to make more changes or go back to the ${title.value}.` :
          `We don't have a redirect location. Please navigate back to the ${title.value} site for your company.`
      });

      const dismiss_button_text = computed(() => redirect_url.value ? "Make More Changes" : "Dismiss");

      const redirect_button_text = computed(() => redirect_url_hostname.value ? `Continue to ${redirect_url_hostname.value}` : "I'm Done");

      const dismiss = () => finished_saving(false);
      const redirect = () => window.location.href = redirect_url.value || 'https://www.growmark.com';

      return {
        dismiss_button_text,
        is_address_verified,
        is_affiliation_verified,
        is_grower_id_verified,
        redirect_button_text,
        redirect_message,
        redirect_url,
        redirect_url_hostname,
        show_dialog,
        title,
        dismiss,
        redirect
      };
    }
  });
</script>

<style>

</style>