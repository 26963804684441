import { Module, ActionContext, Plugin } from 'vuex';
import cloneDeep from 'lodash-es/cloneDeep';
import { claims } from '@growmark/cognito-vue';

export const NAMESPACE = 'user';
export type State = typeof initial;
const initial = {
  identity: <{ email: string } | undefined>undefined
};

export const plugin: Plugin<MinimalRootState> = async (store) => {
  try {
    const { email } = await claims;
    store.commit(`${NAMESPACE}/IDENTITY`, { email });
  } catch (err) {
    store.commit(`${NAMESPACE}/IDENTITY`, undefined);
  }
};

export type Actions = typeof actions;
type MinimalRootState = { [NAMESPACE]: State };
type UserActionContext = ActionContext<State, MinimalRootState>;
export const actions = {
  identity: async ({ commit }: UserActionContext, identity: typeof initial.identity) => {
    commit('IDENTITY', identity)
  }
};

export type Getters = typeof getters;
export const getters = {
  email: (state: State) => state.identity && state.identity.email
};

export type Mutations = typeof mutations;
export const mutations = {
  IDENTITY: (state: State, identity: typeof initial.identity) => {
    state.identity = identity;
  }
};

export const module: Module<State, MinimalRootState> = {
  namespaced: true,
  state: cloneDeep(initial),
  getters,
  mutations,
  actions
};