<template>
  <v-dialog
    :value="!!error_message"
    width="500"
    persistent
  >
    <v-card>
      <v-card-title class="text-h5 grey lighten-2">
        Error
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12"></v-col>
            <v-col cols="12" class="red--text text--lighten-2 red lighten-5 rounded-lg">
              <v-icon
                large
                color="red darken-2"
              >
                mdi-alert-circle
              </v-icon>
              {{ error_message }}
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          text
          @click="dismissErrorDialog"
        >
          Dismiss
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
  import { defineComponent } from 'vue';
	import * as errorStore from "@/store/error";

  export default defineComponent({
    name: 'error-dialog',
    setup() {
      const { message: error_message } = errorStore.useGetters(['message']);
      const dismissErrorDialog = () => errorStore.useActions(['clear_error']).clear_error();

      return {
        error_message,
        dismissErrorDialog
      };
    }
  });
</script>

<style>

</style>