import { Module, ActionContext } from 'vuex';
import cloneDeep from 'lodash-es/cloneDeep';

export const NAMESPACE = 'error';
export type State = typeof initial;
const initial = {
  error: {},
  message: ''
};

export type Actions = typeof actions;
type MinimalRootState = { [NAMESPACE]: State };
type UserActionContext = ActionContext<State, MinimalRootState>;
export const actions = {
  report_error: async ({ commit }: UserActionContext, { error, message }: { error: object, message: string }) => {
    console.error(error);
    commit('REPORT_ERROR', { error, message });
  },
  clear_error: async ({ commit }: UserActionContext) => {
    commit('CLEAR_ERROR');
  },
};

export type Getters = typeof getters;
export const getters = {
  message: (state: State) => state.message
};

export type Mutations = typeof mutations;
export const mutations = {
  REPORT_ERROR: (state: State, { error, message }: { error: object, message: string }) => {
    state.error = error;
    state.message = message;
  },
  CLEAR_ERROR: (state: State) => {
    state.error = {};
    state.message = '';
  }
};

export const module: Module<State, MinimalRootState> = {
  namespaced: true,
  state: cloneDeep(initial),
  getters,
  mutations,
  actions
};