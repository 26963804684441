import { Module, ActionContext } from 'vuex';
import cloneDeep from 'lodash-es/cloneDeep';
import { interruptable } from '@growmark/coroutine';
import { useRegistrationAPI, authz, versioned } from '@/composable/api/axois';
import { reactive } from 'vue';
import { ADDRESS } from '@/types/ADDRESS';

export const NAMESPACE = 'address';
export type State = typeof initial;
const initial = {
  addresses: <ADDRESS[]>[]
};

const local = reactive({ loading: false });

export type Actions = typeof actions;
type MinimalRootState = { [NAMESPACE]: State };
type AddressActionContext = ActionContext<State, MinimalRootState>;
export const actions = {
  lookup_address: interruptable(function*({ commit }: AddressActionContext, { address_1, address_2, city, state, zip }: { address_1: string | undefined, address_2: string | undefined, city: string | undefined, state: string | undefined, zip: string | undefined }) {
    yield new Promise(resolve => setTimeout(resolve, 1500));
    local.loading = true;
    try {
      const address = [address_1 || '', address_2 || '',  city || '', state || '', zip || ''].filter(exists => exists).join(' ');
      if (address.trim()) {
        const { data }: { data: ADDRESS[] } = yield useRegistrationAPI([authz, versioned]).get({ url: '/address', query: { address }});
        console.log(`lookup_address: ${JSON.stringify(data)}`);
        commit('UPDATE_ADDRESSES', data);
      }
    } finally {
      local.loading = false;
    }
  })
};

export type Getters = typeof getters;
export const getters = {
  addresses: (state: State) => state.addresses,
  has_addresses: (state: State) => !!state.addresses.length && !local.loading,
  loading: () => local.loading
};

export type Mutations = typeof mutations;
export const mutations = {
  UPDATE_ADDRESSES: (state: State, addresses: ADDRESS[]) => {
    state.addresses = addresses;
  }
};

export const module: Module<State, MinimalRootState> = {
  namespaced: true,
  state: cloneDeep(initial),
  getters,
  mutations,
  actions
};