import axios from "axios";

import * as registration from '@/composable/api/endpoints.registration-api';
import * as shared from '@/composable/api/endpoints.shared-api';
import { APIConfig, ModifiedAxios } from '@growmark/axios/types';
import withConfig from "@growmark/axios/core";
import { bearer, versioned } from '@growmark/axios/config';
import { cognito } from "@growmark/axios/authn/cognito";
import instance from '@growmark/cognito-vue';

axios.defaults.headers.common["Accept"] = "application/json";
axios.defaults.timeout = 30 * 60 * 1000;

//auth
const authz = bearer(cognito(instance), { header: 'Authorization' });

//used config
export { versioned, authz };

export const useRegistrationAPI = (config: APIConfig) : ModifiedAxios<registration.ENDPOINTS> => {
	const axiosConfig = {
		baseURL: `${process.env.VUE_APP_GROWER_REGISTRATION_API}/${process.env.VUE_APP_GROWER_REGISTRATION_API_VERSION}`,
		timeout: 30 * 1000
	};
	const myAxios = axios.create(axiosConfig);
	return withConfig<registration.ENDPOINTS>(config)(myAxios);
};

export const useSharedAPI = (config: APIConfig) : ModifiedAxios<shared.ENDPOINTS> => {
	const axiosConfig = {
		baseURL: `https://api.growmark.com/fsMobileShared/v1/pull`,
		timeout: 30 * 1000
	};
	const myAxios = axios.create(axiosConfig);
	return withConfig<shared.ENDPOINTS>(config)(myAxios);
};

export default useRegistrationAPI;