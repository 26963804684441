<template>
  <v-app>
    <v-app-bar
      app
      color="primary"
      dark
    >
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-toolbar-title
            v-bind="attrs"
            v-on="on"
          >
            <v-avatar tile size="30">
              <v-img src="@/assets/FS Logo.png" class="rounded"></v-img>
            </v-avatar>
            {{ title }}
          </v-toolbar-title>
        </template>
        <span>
          {{ title }}
        </span>
      </v-tooltip>
    </v-app-bar>

    <v-main>
      <v-container fluid>
        <v-fade-transition appear hide-on-leave leave-absolute>
          <router-view name="default" key="site-router" />
        </v-fade-transition>
      </v-container>

      <error-dialog />

      <finished-dialog />
    </v-main>

    <v-footer app color="primary" dark>
      <v-row justify="center" no-gutters dense>
        <v-col cols="auto" class="caption">
          <v-btn x-small href="https://www.growmark.com/Portals/0/Documents/GMKPrivacyPolicy.pdf" target="_blank" color="white" plain>
            <span>Privacy Policy</span>
            <v-icon right x-small>mdi-open-in-new</v-icon>
          </v-btn>
          <v-btn x-small href="https://www.growmark.com/Portals/0/Documents/GMKTermsofUse.pdf" target="_blank" color="white" plain>
            <span>Terms of Service</span>
            <v-icon right x-small>mdi-open-in-new</v-icon>
          </v-btn>
        </v-col>
        <v-spacer />
        <v-col cols="auto" class="caption">
          <span>GROWMARK, Inc.</span>
          <v-icon x-small left right>mdi-copyright</v-icon>
          <span>{{ new Date().getFullYear() }}</span>
        </v-col>
      </v-row>
    </v-footer>
  </v-app>
</template>

<script lang="ts">
  import { computed, defineComponent } from "vue";
  import { useRoute } from 'vue-router/composables'
  import finishedDialog from "./components/finished-dialog.vue";
  import errorDialog from "./components/error-dialog.vue";

  export default defineComponent({
    name: 'main-application',
    components: {
      'finished-dialog': finishedDialog,
      'error-dialog': errorDialog
    },
    setup () {
      const route = useRoute();

      const title = computed(() => route?.meta?.title || "GROWMARK Solution Center");

      return {
        title
      };
    }
  });
</script>