import Vue from 'vue';
import router from '@/plugins/vue-router';
import store from '@/plugins/vuex';
import Application from '@/Application.vue';
import vuetify from '@/plugins/vuetify';
import VueTelInputVuetify from 'vue-tel-input-vuetify';
import '@/plugins/leaflet';

Vue.config.productionTip = false;

Vue.use(VueTelInputVuetify, {
  vuetify,
});

const app = new Vue({ router, store, vuetify, render: h => h(Application) });
app.$mount('#app');