import Vue from 'vue';
import 'vuetify/dist/vuetify.min.css';
import Vuetify from 'vuetify';
import colors from 'vuetify/lib/util/colors';
import { VuetifyPreset } from 'vuetify/types/services/presets';
import Lib from 'vuetify/lib';

Vue.use(Vuetify);


Lib.config.silent = !['local'].find(env => process.env.NODE_ENV === env);
const options: VuetifyPreset = { //https://vuetifyjs.com/en/features/presets/#default-preset
  breakpoint: {
    mobileBreakpoint: 'xs',
    scrollBarWidth: 16,
    thresholds: {
      xs: 600,
      sm: 960,
      md: 1280,
      lg: 1920,
    }
  },
  icons: {
    iconfont: 'mdi',
    values: { }
  },
  lang: {
    t: () => '',
    locales: {},
    current: 'en'
  },
  rtl: false,
  theme: {
    dark: false,
    default: 'light',
    disable: false,
    options: {
      //cspNonce: undefined,
      customProperties: true,
      //minifyTheme: undefined,
      //themeCache: undefined,
    },
    themes: {
      light: {
        /* custom colors and specify colors */
        primary: colors.grey.darken3,
        secondary: colors.red.darken4,
        accent: colors.cyan, //'#8c9eff'
        error: colors.red.base, //'#b71c1c'
        info: colors.orange,
        success: colors.green,
        warning: colors.yellow.darken3
      },
      dark: {
        /* custom colors and specify colors */
        primary: colors.blue, 
        secondary: colors.lightBlue,
        accent: colors.cyan,
        error: colors.red.base,
        info: colors.orange,
        success: colors.green,
        warning: colors.yellow.darken3
      },
    }
  }
};
const vuetify = new Vuetify(options);
export default vuetify;