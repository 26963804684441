import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import * as growerStore from '@/store/grower';

Vue.use(VueRouter)

const not_found: RouteConfig = {
  path: '*',
  components: { default: () => import('@/views/404.vue') }
};
const myfs: RouteConfig = {
  path: '/myfs',
  components: { default: () => import('@/views/myFS.vue') },
  meta: {
    auth: true,
    title: "myFS Solution Center"
  },
  beforeEnter: (to, __, next) => {
		if (to?.query?.redirect_url) {
      growerStore.useActions(['save_redirect_url']).save_redirect_url(to?.query?.redirect_url?.toString());
    }
		next();
	}
};
const agapps: RouteConfig = {
  path: '/ag-apps',
  components: { default: () => import('@/views/AgApps.vue') },
  meta: {
    auth: true,
    title: "Agronomy Applications Solution Center"
  },
  beforeEnter: (to, __, next) => {
		if (to?.query?.redirect_url) {
      growerStore.useActions(['save_redirect_url']).save_redirect_url(to?.query?.redirect_url?.toString());
    }
		next();
	}
};

const routes = [
  /* other routes go here */
  not_found, myfs, agapps,
];

const router = new VueRouter({ mode: 'history', base: '/', routes });

export default router
