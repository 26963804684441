import Vue from 'vue';
import Vuex, { Plugin, ModuleTree, GetterTree, ActionTree, MutationTree } from 'vuex';
import * as address from '@/store/address/state';
import * as grower from '@/store/grower/state';
import * as user from '@/store/user/state';
import * as error from '@/store/error/state';

Vue.use(Vuex);

type State = typeof state;
const state = {
  version: `${process.env.VUE_APP_VERSION}`.replace('v','')
};

const actions: ActionTree<State, State> = { };

const getters: GetterTree<State, State> = {
  site: (state: State) => `v${state.version}`
};

const mutations: MutationTree<State> = { };

const modules: ModuleTree<any> = {
  [user.NAMESPACE]: user.module,
  [address.NAMESPACE]: address.module,
  [grower.NAMESPACE]: grower.module,
  [error.NAMESPACE]: error.module
};

const plugins: Array<Plugin<any>> = [
  user.plugin,
  grower.plugin
];

/** Promise to handle async plugins */
const vuex = new Vuex.Store({
  strict: true,
  state,
  getters,
  mutations,
  actions,
  modules,
  plugins
});

export default vuex;